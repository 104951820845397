// import logo from './logo.svg';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';

import './App.css';
import Home from './pages';
import Footer from './components/Footer';

function App() {
  const [getReadyTimer, setGetReadyTimer] = useState(5);
  const [timerDone, setTimerDone] = useState(false);
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      const timer = setInterval(() => {
        setGetReadyTimer((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            // console.log('Timer finished');
            // Add any logic you want to perform when the timer finishes
            setIsAnimationActive(true)
            setTimerDone(true)
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }, 1000);

    return () => clearTimeout(delayTimer);
  }, []);

  return (
    <div className="App">
      <div className={`transition-all duration-[1500ms] fixed flex items-center justify-center z-[9999] top-0 left-0 w-screen h-screen bg-white ${timerDone ? 'opacity-0 invisible' : 'opacity-100 visible'}`}>
        <motion.div
          initial={{ opacity: 0 }} // Initial animation properties
          animate={{ opacity: 1 }} // Animation properties to transition to
          transition={{
            duration: 0.75,
            delay: 0.5
          }} // Transition duration
        >
          <p className='text-[12rem] linotte text-lime-600'>
            {getReadyTimer}
          </p>
        </motion.div>
      </div>
      {/* <div className="absolute w-full h-full bg-gradient-radial from-white to-white/20" /> */}
      {/* <div className="absolute w-full h-full bg-repeat bg-[length:550px_550px] ptn bg-ptn opacity-20 mix-blend-luminosity" /> */}
      {/* <div className="absolute z-10 w-full h-full bg-gradient-radial from-white to-white/50" /> */}
      <BrowserRouter>
        {isAnimationActive && (
          <motion.div
            initial={{ opacity: 0 }} // Initial animation properties
            animate={{ opacity: 1 }} // Animation properties to transition to
            transition={{
              delay: 1,
              duration: 1.2
            }} // Transition duration
          >
            <Routes>
              <Route path='/' element={<Home />} />
            </Routes>
          </motion.div>
        )}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
