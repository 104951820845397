import React, { useState, useEffect } from 'react';
// import { HiOutlineChevronUp } from "react-icons/hi";
import { FaChevronUp } from "react-icons/fa";

const ScrollToTopBtn = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 500) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <button
            onClick={scrollToTop}
            className={`transition-all duration-[750ms] fixed z-[999] bottom-4 right-4 bg-btnGreen xsm:active:bg-lime-500 xl:hover:bg-lime-500 text-lime-400 active:text-btnGreen xl:hover:text-btnGreen text-[1.05rem] p-[1rem] rounded-[4px] rounded-bl-[21px] ${showButton ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
        >
            <FaChevronUp />
        </button>
    )
}

export default ScrollToTopBtn