import React, { useState, useEffect, useRef } from 'react';
// import { NavBarScrollSpy } from 'react-navbar-scroll-spy';
import { useLocation } from 'react-router-dom';
import { IoMdClose } from "react-icons/io";

import Navbar from '../components/Navbar';
import ScrollToTopBtn from '../components/ScrollToTopBtn';
// import HeaderImg from '../assets/img/header.webp'
import Henk1 from '../assets/img/henk_1.webp'
import Henk2 from '../assets/img/henk_2.webp'
import CarbonFoot from '../assets/img/cbfp.webp'
import Client1 from '../assets/img/clients/client1.webp'
import Client2 from '../assets/img/clients/client2.webp'
import Client3 from '../assets/img/clients/client3.webp'
import Client4 from '../assets/img/clients/client4.webp'
// import Popup from '../components/Popup';


const Home = () => {
    const [activeItem, setActiveItem] = useState(1);
    const [contentOffsets, setContentOffsets] = useState([]);
    const contentRefs = useRef([]);
    const location = useLocation();
    const lastHash = useRef('');
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        const handleScroll = () => {
            const sectionIds = ['home', 'about', 'story', 'services', 'testimonials', 'joinus'];
            const threshold = 10;

            let newActiveSection = null;

            sectionIds.forEach(sectionId => {
                const sectionDiv = document.getElementById(sectionId);
                if (!sectionDiv) return;

                const rect = sectionDiv.getBoundingClientRect();
                const bottomThreshold = window.innerHeight - threshold;

                if (rect.bottom >= 0 && rect.bottom <= bottomThreshold) {
                    newActiveSection = sectionId;
                }
            });

            setActiveSection(newActiveSection);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    /* useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const contentHeightThreshold = 100; // Minimum height threshold for a section

            let topIndex = 0;
            let bottomIndex = contentRefs.current.length - 1;

            for (let i = 0; i < contentRefs.current.length; i++) {
                const { top, bottom } = contentRefs.current[i].getBoundingClientRect();
                const height = contentRefs.current[i].offsetHeight;

                const middle = windowHeight / 2;

                if (height < contentHeightThreshold && i < contentRefs.current.length - 1) {
                    // If the height is less than the threshold, consider the next section as the top boundary
                    const nextSectionTop = contentRefs.current[i + 1].getBoundingClientRect().top;
                    if (nextSectionTop <= middle) {
                        topIndex = i + 1;
                    }
                } else {
                    if (top < middle && bottom > middle) {
                        setActiveItem(i);
                        return; // Exit loop once the topmost visible section is found
                    }

                    if (top < middle && bottom > middle + height / 2) {
                        topIndex = i;
                    }

                    if (bottom > middle && top < middle - height / 2) {
                        bottomIndex = i;
                    }
                }
            }

            // Set active item to the one closest to the center if no section is fully in view
            const centerIndex = Math.floor((topIndex + bottomIndex) / 2);
            setActiveItem(centerIndex);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); */

    // console.log(activeSection)

    /*  useEffect(() => {
         if (location.hash) {
             lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
         }
 
         if (lastHash.current && document.getElementById(lastHash.current)) {
             setTimeout(() => {
                 const element = document.getElementById(lastHash.current);
                 if (element) {
                     const offset = element.getBoundingClientRect().top + window.scrollY - 100;
                     window.scrollTo({ top: offset, behavior: 'smooth' });
                 }
                 lastHash.current = '';
             }, 100);
         }
     }, [location]); */

    const handleItemClick = (index) => {
        setActiveItem(index);
        contentRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    };


    const contentItems = [
        { id: 'home', content: 'Home', link: '#home', isActive: activeItem === 0 },
        { id: 'about', content: 'About', link: '#about', isActive: activeItem === 1 },
        { id: 'story', content: 'Our Story', link: '#story', isActive: activeItem === 2 },
        { id: 'services', content: 'Services', link: '#services', isActive: activeItem === 3 },
        { id: 'testimonials', content: 'Testimonials', link: '#testimonials', isActive: activeItem === 4 },
        { id: 'joinus', content: 'Join Us', link: '#joinus', isActive: activeItem === 5 },
    ];

    const testimonials = [
        {
            id: 1,
            pic: Client2,
            name: 'Erik Klein Wolterink',
            job: 'Top Photographer',
            link: 'https://www.youtube.com/embed/B6X2f6CJ6YI ',
        },
        {
            id: 2,
            pic: Client3,
            name: 'Sjoerd Boersma',
            job: 'Founder Learning Stone \nBlended Learning for All',
            link: 'https://www.youtube.com/embed/0zSP4Zeptsk',
        },
        {
            id: 3,
            pic: Client1,
            name: 'Marc Prosman',
            job: 'Architect Partner Prosman en\n De Wit architecten',
            link: 'https://www.youtube.com/embed/nCzmwaeJIVY',
        },
        {
            id: 4,
            pic: Client4,
            name: 'Niek Zonneveld',
            job: 'Renowned Viticulturist',
            link: 'https://www.youtube.com/embed/7D64875WHSY',
        },
    ]

    const [showPopup, setShowPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [videoLink, setVideoLink] = useState('');
    const videoWindowRef = useRef(null);
    const iframeRef = useRef(null);


    const openPopup = (link) => {
        setVideoLink(link);
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
        // iframeRef.current.pause();
    };

    return (
        <div>
            <ScrollToTopBtn />
            <div className={` transition-all duration-500 fixed z-[999] top-0 left-0 flex items-center justify-center w-full h-full xsm:bg-black/70 md:bg-black/85 ${showPopup ? 'visible opacity-100' : 'invisible opacity-0'}`}>
                <div
                    onClick={() => {
                        setShowPopup(false)
                    }}
                    className="fixed w-full h-full z-[99]" />
                <div
                    className="xsm:p-[0.25rem] md:p-[0.5rem] xsm:w-[98.5%] xsm:h-[35%] md:w-[80%] md:h-[60%] xl:w-[70%] xl:h-[80%] 2xl:w-[60%] 2xl:h-[70%] bg-paleGreen xsm:rounded-[4px] md:rounded-[12px]">
                    <button className=" transition-all duration-500 absolute z-[999] text-white bg-red-700 hover:bg-red-900 rounded-[4px] p-[0.85rem] text-[1.75rem] top-1 right-1"
                        onClick={closePopup}
                    >
                        <IoMdClose />
                    </button>
                    <div
                        // ref={videoWindowRef}
                        className='relative z-[999] w-full h-full'>
                        <iframe
                            // ref={videoWindowRef}
                            width="100%"
                            height="100%"
                            src={videoLink}
                            title="YouTube Video"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="fixed w-full h-full bg-repeat xsm:bg-[length:350px_350px] xl:bg-[length:550px_550px] ptn bg-ptn xsm:opacity-40 xl:opacity-40 mix-blend-luminosity" />
            <div className="fixed z-10 w-full h-full xsm:bg-gradient-radial-xsm xl:bg-gradient-radial from-white to-white/0" />
            <div className='relative flex flex-col xsm:w-[97.5%] md:w-[90%] xl:w-[85%] 2xl:w-[75%] items-center mx-auto z-50'>
                <div className='relative z-[99]'>
                    <Navbar
                        contentItems={contentItems}
                        activeItem={activeItem}
                        setActiveItem={handleItemClick}
                        activeSection={activeSection}
                    />
                </div>
                {/* Render content items */}
                <div
                    id='home'
                    ref={(ref) => (contentRefs.current[0] = ref)}
                    className="relative xsm:-mt-[5rem] xl:mt-0 w-full xsm:h-[35rem] xsm:bg-[-5rem_5rem] xsm:bg-[length:900px] md:bg-cover md:h-[40rem] xl:h-[calc(80vh-5rem)] bg-header bg-no-repeat xl:bg-cover xl:bg-[center_top_0rem] overflow-hidden xl:rounded-b-[12px] xl:rounded-bl-[220px] z-20">
                    {/* <img
                        className='object-cover xsm:h-full xsm:w-auto xl:w-full'
                        src={HeaderImg} 
                        alt="Welcome to Carbylon"
                    /> */}
                </div>
                <div
                    // key={contentItems[0].id}
                    className="relative flex flex-col intro xsm:w-full xl:w-[45%] 2xl:w-[42.5%] 3xl:w-[41.5%] xsm:h-[11rem] xl:h-[13rem] 2xl:h-[13.5rem] xsm:-mt-[7.75rem] xl:-mt-[9.25rem] 2xl:-mt-[9.5rem] bg-gradient-to-b from-white/30 via-white/70 to-white xsm:border-x-[4px] xl:border-[3px] 2xl:border-[4px] border-white/20 backdrop-blur-[10px] xsm:rounded-[0px] xsm:rounded-br-[8px] xl:rounded-[12px] xsm:rounded-bl-[60px] xl:rounded-bl-[96px] z-20 shadow-3xl">
                    <div className='flex flex-col items-center justify-center h-[70%]'>
                        <h1 className='linotte lowercase text-lime-600 xsm:text-[1.95rem] xl:text-[2.35rem] 2xl:text-[2.5rem]'>
                            Welcome to Carbylon
                        </h1>
                        <h2 className='linotte lowercase text-gray-600 xsm:text-[1.35rem] xl:text-[1.65rem] 2xl:text-[1.75rem] xsm:-mt-[0.5rem] xl:-mt-[0.75rem]'>
                            Simplifying the Carbon World
                        </h2>
                    </div>
                    <div className='flex items-center justify-center xsm:px-[0rem] xl:px-[2rem] 2xl:px-[3rem] h-[30%]'>
                        <div className="flex items-center justify-center w-full h-full border-t xsm:border-gray-600/20 xl:border-gray-600/30">
                            <h3 className='linotte uppercase text-gray-600 xsm:tracking-[3px] sm:tracking-[4px] xl:tracking-[4px] 2xl:tracking-[4px] xsm:text-[0.725rem] xl:text-[0.8rem] 2xl:text-[0.825rem] xsm:mt-0 xl:mt-0'>
                                60 years of excellence
                            </h3>
                        </div>
                    </div>
                </div>
                <div
                    className='xsm:w-[97.5%] md:w-[90%] xl:w-[60%] 2xl:w-[65%]'
                >
                    <div
                        id='about'
                        ref={(ref) => (contentRefs.current[1] = ref)}
                        className=' relative z-30 xsm:px-[1.25rem] xsm:mt-[3rem] xl:mt-[3.5rem]'>

                        <h4 className='linotte xsm:text-[1.4rem] xl:text-[1.7rem] 2xl:text-[1.8rem] text-gray-700/90 lowercase pb-[1rem] border-b border-gray-600/30'>
                            About Our Founder
                        </h4>
                        {/* <div className="flex flex-col xsm:gap-y-[1rem] xl:gap-y-[1.5rem] mt-[1.5rem] xsm:w-[97.5%] lg:w-[85%] xl:w-[80%] mx-auto">
                            <img
                                className='bg-white xsm:p-[0.25rem] xl:p-[0.5rem] shadow-2xl rounded-[8px]' src={Henk2}
                                alt="Henk Harmsen"
                            />
                            <img
                                className='bg-white xsm:p-[0.25rem] xl:p-[0.5rem] shadow-2xl rounded-[8px]'
                                src={Henk1}
                                alt="Henk Harmsen"
                            />
                        </div> */}
                        <p className='geom xsm:text-justify alignLastCenter xl:text-center text-gray-600/90 text-center xsm:text-[0.9rem] xl:text-[1.05rem] mt-[2rem] xsm:leading-[1.75rem] xl:leading-[2.25rem]'>
                            Since I was 7 years old I have held a vision: a world without people would look much better. People make noise, cut trees, disturb birds and cause global warming. Now that I am turning 60, I take a bold step that will divide the world in 2, you are with me or you get lost in a world that gets dominated by carbon.
                        </p>
                        <div className="flex flex-col xsm:w-full lg:w-[90%] xl:w-[95%] mt-[1.5rem] mx-auto">
                            <img
                                className='bg-white xsm:p-[0.25rem] xl:p-[0.35rem] shadow-2xl rounded-[8px]'
                                src={Henk1}
                                alt="Henk Harmsen" />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="relative flex flex-col items-center w-full bg-gradient-to-b from-gray-100 via-gray-50 to-white border-t-[4px] border-lime-800/[12.5%] h-auto xsm:mt-[3rem] xl:mt-[3.5rem] 2xl:mt-[5rem] z-30">
                <div className="absolute w-full h-full bg-repeat bg-[length:800px_800px] ptn bg-ptn2 opacity-[10%] mix-blend-luminosity z-30" />
                <div
                    className="relative z-30 xsm:w-[97.5%] md:w-[85%] xl:w-[60%] 2xl:w-[47.5%] h-auto pb-[3rem]">
                    <div
                        id='story'
                        ref={(ref) => (contentRefs.current[2] = ref)}
                        className='mb-[4rem] pt-[3rem] xl:px-[2rem]'
                    >
                        <div
                            className="relative z-30 bg-gradient-to-b from-white/0 via-white to-white/0 border-[6px] border-white/20 rounded-[12px] xsm:rounded-bl-[52px] xl:rounded-bl-[60px] 2xl:rounded-bl-[80px] shadow-3xl w-full h-auto">
                            <h5
                                className='linotte flex items-center justify-center lowercase bg-gray-300/35 xsm:h-[4rem] xl:h-[5rem] text-gray-700/90 xsm:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[1.8rem] rounded-t-[8px]'>
                                Our Story
                            </h5>
                            <div className='geom flex flex-col items-center w-full h-auto xsm:py-[2.25rem] xl:py-[2.5rem] xsm:px-[1.5rem] md:px-[4rem] xl:px-[5rem] 2xl:px-[6rem]'>
                                <p className='xsm:text-justify xl:text-center xsm:text-[0.9rem] xl:text-[1rem] text-gray-600/90 xsm:leading-[1.5rem] md:leading-[1.75rem] xl:leading-[2.15rem]'>
                                    In early 2000 I started carbonmetrics, we were measuring GHG emissions and negotiated the Dutch carbon emissions trading platform.
                                    <br />
                                    <br />
                                    The world looked simple: As a company you measure and you reduce your CO2 emission to remain under your ceiling. If you exceed you buy from a company that has a surplus of CO2.
                                    <br />
                                    <br />
                                    It was just me with a few others who were dealing with the matter. Fast forward: 70,000 people visiting CoP28, creating the Carbon Babylon where we are today.
                                    <br />
                                    <br />
                                </p>
                                <p className='text-gray-600 xsm:text-[0.9rem] xl:text-[1.05rem]'>We need change!</p>
                            </div>
                        </div>
                    </div>
                    <div
                        id='services'
                        ref={(ref) => (contentRefs.current[3] = ref)}
                        className=' mb-[3rem]'
                    >
                        <div
                            className="relative xsm:px-[2rem] md:px-[4rem] xl:px-[8rem] 2xl:px-[9rem] xsm:text-justify xl:text-center">
                            <h5 className='linotte flex items-center justify-center lowercase text-gray-700/90 xsm:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[1.8rem] pb-[1.5rem] border-b border-gray-600/30'>
                                Services We Offer
                            </h5>
                            <div className="flex flex-col items-center justify-center w-full py-[2rem]">
                                <img
                                    className='rounded-bl-[4px] xsm:w-[4rem] xl:w-[5rem] pb-[2rem] border-b-[4px] border-lime-400'
                                    src={CarbonFoot}
                                    alt="Carbon Footprint" />
                                <p className='geom mt-[2rem] text-gray-600/90 leading-[1.5rem] xl:leading-[2rem] xsm:text-[0.9rem] xl:text-[1rem]'>
                                    A babylonian confusion in the form of a pile of tens of thousands of papers, guidelines and protocols on greenhouse gas has descended on humankind as a penalty for our contribution to global warming.
                                    <br />
                                    <br />
                                    Carbylon will destroy this pile of papers produced by the thousands of so-called carbon and climate experts who were all doing something else in the recent past and other Nimrod-type of individuals.
                                    <br />
                                    <br />
                                    Through our cutting edge data analytics, artificial intelligence and machine learning tools, we offer companies simple, easy to understand, actionable GHG management tools and advice.
                                    Call us and your GHG emissions reduction becomes a walk in the park, filled with trees and birds.
                                    <br />
                                    <p className='text-center text-gray-500 mt-[1rem]'>and more...</p>
                                </p>
                            </div>
                            <div className="flex flex-col xsm:gap-y-[1rem] xl:gap-y-[1.5rem] mb-[1.5rem] xsm:w-full md:w-[90%] xl:w-[95%] mx-auto">
                                <img
                                    className='bg-white xsm:p-[0.25rem] xl:p-[0.35rem] shadow-2xl rounded-[8px]'
                                    src={Henk2}
                                    alt="Henk Harmsen" />
                            </div>
                        </div>
                    </div>
                    <div
                        id='testimonials'
                        ref={(ref) => (contentRefs.current[4] = ref)}
                        className='mb-[2rem]'
                    >
                        <div
                            className="xsm:px-[2rem] md:px-[4rem] xl:px-[8rem] 2xl:px-[9rem] text-center">
                            <h5 className='linotte flex items-center justify-center lowercase text-gray-700/90 xsm:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[1.8rem] pb-[1rem] border-b border-gray-600/30'>
                                Client Testimonials
                            </h5>
                            <div className="flex flex-col items-center justify-center w-full py-[2rem]">
                                {/* <img className='w-[5rem] pb-[2rem] border-b border-lime-600' src="/assets/img/cbfp.webp" alt="" /> */}
                                <p className='geom xsm:text-justify xl:text-center mt-[0rem] text-gray-600/90 leading-[1.5rem] xl:leading-[2rem] xsm:text-[0.95rem] xl:text-[1rem]'>
                                    But don't just take our word for it.
                                    Explore heartfelt testimonials from our valued clients, showcasing the real-world impact of our solutions. Their stories speak volumes about Henk's dedication to excellence and client satisfaction.
                                </p>
                            </div>
                        </div>
                        <div className='grid xsm:grid-cols-1 md:grid-cols-2 xsm:gap-y-[1.5rem] md:gap-[1rem] h-auto w-[70%] mx-auto mb-[3rem]'>
                            {testimonials.map((item, index) => (
                                <>
                                    <div
                                        key={item.id}
                                        className="flex flex-col items-center flex-grow">
                                        <div className='flex flex-col items-center xsm:justify-center md:justify-start bg-white/40 border-[2px] border-gray-600/15 w-full h-[16.5rem] pt-[1.5rem] px-[1rem] rounded-t-[8px]'>
                                            <img
                                                className='h-[150px] w-[180px] object-cover border-[2px] border-gray-600/30 rounded-[4px]'
                                                src={item.pic}
                                                alt={item.name}
                                            />
                                            <h6
                                                className='linotte uppercase text-[0.95rem] tracking-[1px] text-gray-700 mt-[0.5rem]'
                                            >
                                                {item.name}
                                            </h6>
                                            <p
                                                className='linotte leading-[1rem] text-gray-600/80 whitespace-pre-wrap'
                                            >
                                                {item.job}
                                            </p>
                                        </div>
                                        <button
                                            className='xsm:hidden xl:flex transition-all duration-300 cursor-pointer items-center justify-center h-[3.75rem] w-full bg-btnGreen hover:bg-lime-500 linotte lowercase text-lime-400/95 hover:text-btnGreen xsm:text-[0.95rem] xl:text-[0.975rem] rounded-br-[6px] rounded-bl-[40px]'
                                            onClick={() => openPopup(item.link)}
                                        >
                                            Watch Testimonial
                                        </button>
                                        <a
                                            href={item.link}
                                            target='_blank'
                                            className='xsm:flex xl:hidden transition-all duration-300 cursor-pointer flex items-center justify-center h-[3.5rem] w-full bg-btnGreen active:bg-lime-500 linotte lowercase text-lime-400/95 active:text-btnGreen xsm:text-[0.95rem] xl:text-[0.975rem] rounded-br-[6px] rounded-bl-[40px]'
                                            rel="noreferrer"
                                        >
                                            Watch Testimonial
                                        </a>
                                    </div>
                                </>
                            ))}
                        </div>

                        {/* <div className='flex items-center justify-center bg-white/60 shadow-3xl rounded-[12px] xsm:w-[95%] md:w-[80%] xl:w-[90%] 2xl:w-[85%] 3xl:w-[70%] mx-auto xsm:h-[14rem] md:h-[20rem] xl:h-[30rem] 2xl:h-[28rem] 3xl:h-[24rem] xsm:p-[0.25rem] xl:p-[0.35rem]'>
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/playlist?list=PLerguFw1_sn6j8LIJBpBURnooP_PJrL_D"
                                title="YouTube Playlist Player"
                                // frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className='rounded-[10px]'
                            ></iframe>
                        </div> */}
                    </div>

                    <div
                        id='joinus'
                        ref={(ref) => (contentRefs.current[5] = ref)}
                        className="xsm:px-[2rem] md:px-[4rem] xl:px-[8rem] 2xl:px-[9rem] xsm:text-justify alignLastCenter xl:text-center">
                        <h5 className='linotte flex items-center justify-center lowercase text-gray-700 xsm:text-[1.4rem] xl:text-[1.6rem] 2xl:text-[1.8rem] pb-[1rem] border-b border-gray-600/30'>
                            Join Us
                        </h5>
                        <div className="flex flex-col items-center justify-center w-full py-[2rem]">
                            {/* <img className='w-[5rem] pb-[2rem] border-b border-lime-600' src="/assets/img/cbfp.webp" alt="" /> */}
                            <p className='geom mt-[0rem] text-gray-600/90 leading-[1.5rem] xl:leading-[2rem] xsm:text-[0.95rem] xl:text-[1rem]'>
                                Join us in celebrating a legacy of innovation and impact at Carbylon. Explore our website to learn more about our impactful and visionary founder Henk Harmsen and the exciting journey ahead.
                            </p>
                        </div>
                    </div>
                    <div className='linotte lowercase xsm:mt-[1.5rem] xl:mt-[2rem] xsm:px-[1.25rem] xl:px-[3rem] 2xl:px-[6rem]'>
                        <p className='xsm:pt-[2rem] xl:pt-[1.5rem] text-gray-600/90 xsm:text-[0.9rem] 2xl:text-[0.925rem] leading-[1.25rem] border-t-[5px] border-dotted border-gray-600/10'>
                            Thank you for visiting Carbylon
                            <br />
                            Where we make navigating the carbon world simple, insightful, and impactful.
                        </p>
                    </div>
                </div>
            </div>
            {/* {contentItems.map((item) => (
                    <div className='relative flex justify-center w-full'>
                        <div key={item.id} className="content-item flex items-start w-[80%] my-[5rem] h-[40rem] gap-y-[1rem]">
                            {item.content}
                        </div>
                    </div>
                ))} */}
        </div>
    )
}

export default Home