import React from 'react'
import { RiTwitterXFill, RiFacebookCircleFill, RiLinkedinBoxFill } from "react-icons/ri";

import LogoWhite from '../assets/img/logo_white.webp'
import DesignByLinchpin from '../assets/img/dbl.webp'

const Footer = () => {
    return (
        <div className='relative flex flex-col items-center justify-center w-full h-auto bg-darkGreen xsm:pt-[1.5rem] xl:pt-[2rem] z-[60]'>
            <div className='xsm:w-[85%] xl:w-[50%] flex h-[7rem]'>
                <div className='w-[50%] border-r border-lime-600/40 h-full flex flex-col items-start justify-center text-left'>
                    <img
                        className='xsm:w-[7.5rem] xl:w-[10rem] -ml-[0.15rem]'
                        src={LogoWhite}
                        alt="Carbylon"
                    />
                    <p className='xsm:mt-[0.15rem] xl:mt-[0.25rem] uppercase linotte xsm:text-[0.5rem] md:text-[0.55rem] xl:text-[0.65rem] xsm:leading-[0.75rem] xl:leading-[0.85rem] text-lime-400/90 xsm:tracking-[2px] xl:tracking-[3px]'>
                        Copyright &copy;{new Date().getFullYear()},
                        <br />
                        Carbylon
                        <br />
                        <span className='opacity-70'>All Rights Reserved.</span>
                    </p>
                </div>
                <div className='flex items-center justify-end w-[50%] h-full'>
                    <div className='flex flex-col items-end justify-center text-right'>
                        <h6 className='mt-[0] lowercase linotte xsm:text-[0.85rem] xl:text-[1rem] text-lime-400/90 tracking-[0px]'>
                            Connect with us:
                        </h6>
                        <ul className='flex justify-end w-full text-white xsm:text-[1.45rem] xl:text-[1.5rem] mt-[0.25rem] xsm:gap-x-[1.5rem] xl:gap-x-[1.5rem]'>
                            <li className='cursor-not-allowed'>
                                <RiTwitterXFill />
                            </li>
                            <li className='cursor-not-allowed'>
                                <RiFacebookCircleFill />
                            </li>
                            <li className='cursor-not-allowed'>
                                <RiLinkedinBoxFill />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center w-full h-[3rem] bg-darkerGreen border-t-[2px] border-lime-500/20 mt-[2rem]">
                <a
                    href='https://linchpin.co.ke'
                    target='_blank'
                    className='transition-opacity duration-300  opacity-80 hover:opacity-100'
                    rel="noreferrer"
                >
                    <img
                        className='xsm:w-[10rem] xl:w-[11rem]'
                        src={DesignByLinchpin}
                        alt="Designed By Linchpin"
                    />
                </a>
            </div>
        </div>
    )
}

export default Footer