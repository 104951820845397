import React, { useState, useEffect, useRef } from 'react';
// import NavbarItem from './NavbarItem';

import { Twirl as Hamburger } from 'hamburger-react'
import { Link, NavLink } from 'react-router-dom';

import Logo from '../assets/img/logo.webp'

const Navbar = ({ contentItems, setActiveItem, activeSection }) => {
    const [isOpen, setOpen] = useState(false)
    const [animate, setAnimate] = useState(false)
    const [isActive, setActive] = useState(false)
    const [activeId, setActiveId] = useState(null);

    const ulRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ulRef.current && !ulRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Find the content section that is currently in view
            const contentElements = document.querySelectorAll('.content-item');
            let activeId = null;
            contentElements.forEach(element => {
                const rect = element.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    activeId = element.id;
                }
            });
            setActiveId(activeId);
            // console.log(activeId);
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className='relative z-[99] flex justify-center w-full'>
            <div className="z-[99] navbar lowercase linotte flex flex-grow items-center fixed xsm:w-full xl:w-[85%] 2xl:w-[75%] xsm:h-[4rem] xl:h-[5rem] mt-[0rem] mb-[4rem] gap-x-[4rem] xsm:px-[0.5rem] xsm:pl-[1rem] xl:px-[3rem] bg-gradient-to-r xsm:from-white xsm:via-white/75 xsm:to-white/35 xl:bg-white/95 xsm:backdrop-blur-[10px] xl:backdrop-blur-0 xsm:border-b-[3px] xl:border-[2px] xsm:border-white/80 xl:border-gray-200/60 xl:rounded-bl-[80px] shadow-2xl">
                <ul
                    className={`xsm:block xl:hidden transition-all duration-500 absolute flex flex-col top-[4rem] right-0 bg-paleLightGreen text-white rounded-bl-[48px] rounded-br-[4px] overflow-hidden shadow-3xl ${isOpen ? ' visible opacity-100' : 'invisible opacity-0'}`}>
                    {contentItems.map((item, index) => (
                        <li className='relative text-white border-b border-lime-400/20 last:border-0'>
                            <Link
                                to={item.link}
                                /*  className={`${item.isActive ? 'text-lime-600 bg-lime-500' : 'w-auto text-slate-600 hover:text-lime-700 border-b-[3px] border-lime-500/0 hover:bg-lime-500'}`} */
                                onClick={() => {
                                    setActiveItem(index)
                                }}
                            >
                                <p className={` transition-all duration-300 text-lime-300/90 w-[12rem] py-[1.45rem] ${item.isActive ? 'text-lime-400/90 hover:text-paleGreen active:text-paleGreen active:bg-lime-500 hover:bg-lime-500' : 'w-auto text-lime-400/90 hover:text-paleGreen active:text-paleGreen active:bg-lime-500 hover:bg-lime-500'}`}>{item.content}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div className="flex items-center logo xsm:w-[12rem] md:w-[9rem] xl:w-[8.5rem] h-full">
                    <img src={Logo} alt="Carbylon" />
                </div>
                <div className='w-full lowercase h-full flex flex-row self-end items-center justify-end xl:gap-x-[2.5rem] 2xl:gap-x-[3rem]'>
                    <div
                        ref={ulRef}
                        className={`h-[48px] w-[48px] rounded-[4px] rounded-bl-[24px] xsm:block xl:hidden ${isOpen ? 'bg-red-800' : 'bg-btnGreen'}`}>
                        <Hamburger
                            toggled={isOpen}
                            toggle={setOpen}
                            rounded={true}
                            size={19}
                            easing="ease-in"
                            distance="lg"
                            duration={0.3}
                            // toggled={open}
                            color={`${isOpen ? 'hsla(85,100%,100%,1)' : 'hsla(80,100%,45%,1)'}`}
                        />
                    </div>
                    {contentItems.map((item, index) => (
                        <Link
                            to={item.link}
                            className={` transition-all duration-300 xsm:hidden xl:block cursor-pointer navbar-item xl:text-[1.1rem] 2xl:text-[1.15rem] py-[0.5rem] ${item.isActive ? 'text-slate-600 focus:text-lime-600' : 'text-slate-600 hover:text-lime-600'}`}
                            onClick={() => {
                                setActiveItem(index)
                            }}
                        >
                            {item.content}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Navbar;